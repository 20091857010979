import React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-full">
            <div className="content">

              <h1 className="underlined">404 NOT FOUND</h1>

            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default NotFoundPage
